import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from './views/Home';
import AboutView from './views/About';
import GamesView from './views/Games';
import ResultsView from './views/Results';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/About',
    name: 'About',
    component: AboutView
  },
  {
    path: '/Games',
    name: 'Games',
    component: GamesView
  },
  {
    path: '/Results',
    name: 'Results',
    component: ResultsView
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
