<template>
  <div>
    <div class="banner banner_index">
      <div class="container">
        <div class="content">
          <img src="/images/banner_go.svg" alt="">
          <h1 text="Glück 20 BALL">Glück 20 BALL</h1>
          <p>UND GEWINNE UNSEREN ÜBERGRÖSSEN PREIS!</p>
        </div>
      </div>
    </div>
    <div class="jackpot">
      <div class="container">
        <img class="title" src="/images/jackpot_title.jpg" alt="">
        <div class="num">
          <li class="sign euro">
            <p>€</p>
          </li>
          <template v-for="(n, index) in formattedJackPot">
            <li v-if="n===','" class="sign" :key="n + index">
              <p>,</p>
            </li>
            <li v-else :key="n + index">
              <p :class="n===','? 'sign':''">{{n}}</p>
            </li>
          </template>
        </div>
        <div class="num_m">{{formattedJackPotText}} Mio. €</div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="heading_box spacing">
          <div class="heading medium">Gewinnzahlen</div>
          <router-link class="more" :to="{ name: 'Results' }">
            Mehr
            <img src="/images/more_arrow.svg" alt="">
          </router-link>
        </div>
        <newest-issue></newest-issue>
        <div class="heading medium spacing line">Vertraut von</div>
        <div class="trust">
          <div class="logo_list">
            <ul>
              <li><img src="/images/logo_1.png" alt=""></li>
              <li><img src="/images/logo_2.png" alt=""></li>
              <li><img src="/images/logo_3.png" alt=""></li>
              <li><img src="/images/logo_4.png" alt=""></li>
              <li><img src="/images/logo_5.png" alt=""></li>
            </ul>
            <ul>
              <li><img src="/images/logo_6.png" alt=""></li>
              <li><img src="/images/logo_7.png" alt=""></li>
              <li><img src="/images/logo_8.png" alt=""></li>
              <li><img src="/images/logo_9.png" alt=""></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newestIssueComponent from '../components/NewestIssue';
import event from '../event';

const jackPotUnit = 1000000;

export default {
  inject: {
    service: 'service'
  },
  components: {
    'newest-issue': newestIssueComponent
  },
  data() {
    return {
      jackPot: jackPotUnit
    }
  },
  methods: {
    async getJackPotAsync() {
      var result = await this.service.getJackPotAsync();
      var xy28JackPot = result.filter(x => x.name === 'XY28')[0];

      if (xy28JackPot)
        this.jackPot = xy28JackPot.value;
    }
  },
  async created() {
    await this.getJackPotAsync();

    event.receive(event.eventNames.newIssue, this.getJackPotAsync);
  },
  beforeDestroy() {
    event.unsubscribe(event.eventNames.newIssue, this.getJackPotAsync);
  },
  computed: {
    formattedJackPot() {
      return this.jackPot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formattedJackPotText() {
      return (this.jackPot / jackPotUnit).toFixed(0);
    }
  }
}
</script>
