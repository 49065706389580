<template>
  <div>
    <div class="banner banner_subpage banner_results">
      <div class="container">
        <div class="content">
          <div class="breadcrumbs">
            <ul>
              <li>
                <router-link to="/">STARTSEITE</router-link><span>/</span>
              </li>
              <li>
                <router-link :to="{ name: 'Results' }">Ergebnisse - Glück 20 BALL</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="main results">
      <div class="container">
        <newest-issue></newest-issue>
        <div class="heading_box spacing">
          <div class="heading large">Historischen Gewinnzahlen</div>
          <div class="date_selecter">
            <div class="today" :class="{'active':isSelectedDateToday}" @click="getTodayHistoryResultsAsync">heute</div>
            <div class="datepicker" :class="{'active':!isSelectedDateToday}" @click="toggleCalendarVisible">
              <div class="icon"><img src="/images/calendar_ic.svg" alt=""></div>
              <div class="date">{{formattedSelectedDate}}</div>
            </div>
            <datepicker v-model="selectedDate" :style="calendarVisible ? 'display:block;':''" @change="handleSelectedDateChangeAsync"></datepicker>
          </div>
        </div>
        <div class="history">
          <div class="history_list" v-for="historyResult in historyResults" :key="historyResult.issueNo">
            <div class="title">
              <p>Gewinnklasse {{historyResult.issueNo}}</p>
              <p>{{historyResult.issueEndTime | formatDateTime}}</p>
            </div>
            <div class="balls">
              <li v-for="drawNumber in historyResult.drawNumbers" :key="historyResult.issueNo + drawNumber">
                <div class="ball">
                  {{drawNumber}}
                </div>
              </li>
            </div>
          </div>
          <div v-if="!isLastPage" class="more_btn" @click="loadMoreHistoryResults">
            Mehr
          </div>
          <div class="empty" v-if="!historyResults.length">
            <div><img src="/images/ic_empty_data.svg" alt=""></div>
            <p>Es wurden keine Ergebnisse gefunden</p>
          </div>
        </div>
      </div>
    </div>
    <div class="loader_wrapper" v-if="isLoading">
      <div class="loader_bg">
        <div class="loader">
          <div class="loader_animate"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datepicker from '../components/Datepicker';
import newestIssueComponent from '../components/NewestIssue';

export default {
  inject: {
    service: 'service'
  },
  components: {
    datepicker,
    'newest-issue': newestIssueComponent
  },
  data() {
    return {
      isLoading: false,
      calendarVisible: false,
      selectedDate: null,
      historyResults: [],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
      }
    }
  },
  methods: {
    toggleCalendarVisible() {
      this.calendarVisible = !this.calendarVisible;
    },
    async handleSelectedDateChangeAsync() {
      this.calendarVisible = false;

      this.pagination.pageNumber = 1;

      this.historyResults = await this.getHistoryResultsAsync();
    },
    async getTodayHistoryResultsAsync() {
      this.calendarVisible = false;

      this.pagination.pageNumber = 1;
      this.selectedDate = this.$momentTz.format('YYYY-MM-DD');

      this.historyResults = await this.getHistoryResultsAsync();
    },
    async getHistoryResultsAsync() {
      var begin = this.$momentTzFromValue(this.selectedDate).startOf('day').utc().format();
      var endDate = this.$momentTzFromValue(this.selectedDate).endOf('day').utc().format();
      var limit = this.pagination.pageSize;
      var skip = (this.pagination.pageNumber - 1) * this.pagination.pageSize;

      this.isLoading = true;
      var result = await this.service.getHistoryAsync(begin, endDate, limit, skip);
      this.isLoading = false;

      this.pagination.totalCount = result.totalCount;

      return result.collection;
    },
    async loadMoreHistoryResults() {
      if (this.isLastPage)
        return;

      this.pagination.pageNumber++;
      var results = await this.getHistoryResultsAsync();

      this.historyResults = this.historyResults.concat(results);
    }
  },
  async created() {
    await this.getTodayHistoryResultsAsync();
  },
  computed: {
    isLastPage() {
      return !(this.pagination.pageNumber < Math.floor((this.pagination.totalCount + this.pagination.pageSize - 1) / this.pagination.pageSize));
    },
    isSelectedDateToday() {
      if (!this.selectedDate)
        return false;

      var m1 = this.$momentTzFromValue(this.selectedDate);
      var mNowTime = this.$momentTz;

      return m1.isSame(mNowTime, 'date');
    },
    formattedSelectedDate() {
      return (this.selectedDate && this.$momentTzFromValue(this.selectedDate).format('MMMM DD,YYYY')) || '';
    }
  }
}
</script>