<template>
  <div class="newest">
    <div class="content">
      <div class="game_logo"><img src="/images/newest_lucky20ball_logo.png" alt=""></div>
      <div class="draw">
        <div class="title">
          <p>Gewinnklasse {{currentIssueNo}}</p>
          <p>{{currentIssueDateTime | formatDateTime}}</p>
        </div>
        <div class="balls">
          <li v-for="(n, index) in drawNumbers" :key="currentIssueNo + '_' + n + '_' + index">
            <div class="ball">
              {{n}}
            </div>
          </li>
        </div>
      </div>
      <div class="next">
        <div class="title">
          <p>Nächsten Gewinnklasse</p>
          <p>{{nextIssueNo}}</p>
        </div>
        <div class="countdown">{{formattedTime}}</div>
      </div>
    </div>
    <div class="line_bottom">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import event from '../event';

export default {
  inject: {
    service: 'service'
  },
  data() {
    return {
      currentIssueNo: '',
      currentIssueDateTime: '',
      nextIssueNo: '',
      seconds: null,
      drawNumbers: ['?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?', '?',],
      countdownTimerId: null,
      issueNoCountdownTimerId: null
    };
  },
  methods: {
    async getNewestAsync() {
      clearInterval(this.issueNoCountdownTimerId);

      var response = {};
      var currentExecuteTimes = 1;
      var maxExecuteTimes = 10;
      var executeDelay = 1500;

      // 取不到就一直取，直到超過最大上限
      this.issueNoCountdownTimerId = setInterval(async () => {
        if (currentExecuteTimes > maxExecuteTimes) {
          clearInterval(this.issueNoCountdownTimerId);
        }
        else {
          currentExecuteTimes++;
          response = await this.service.getCurrentIssueAsync();

          if (response.drawNumbers && response.drawNumbers.length) {
            clearInterval(this.issueNoCountdownTimerId);

            this.currentIssueNo = response.currentIssueNo;
            this.currentIssueDateTime = response.currentIssueDateTime;
            this.nextIssueNo = response.issueNo;
            this.seconds = response.seconds;
            this.drawNumbers = response.drawNumbers;

            event.emit(event.eventNames.newIssue);

            this.countdown();
          }
        }
      }, executeDelay);
    },
    countdown() {
      clearInterval(this.countdownTimerId);

      this.countdownTimerId = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        }
        else {
          clearInterval(this.countdownTimerId);
          this.getNewestAsync();
        }
      }, 1000);
    }
  },
  async created() {
    await this.getNewestAsync();
  },
  beforeDestroy() {
    clearInterval(this.countdownTimerId);
    clearInterval(this.issueNoCountdownTimerId);
  },
  computed: {
    formattedTime() {
      var source = parseInt(this.seconds);

      if (isNaN(source) || source < 0)
        return '00:00';

      var minutes = Math.floor(source / 60);
      var seconds = source % 60;

      return [minutes, seconds]
        .map(x => x < 10 ? '0' + x : x) // 補0
        .join(':');
    }
  }
}
</script>