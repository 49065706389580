import Vue from 'vue';
import App from './App.vue';
import router from './router';
import service from './service';

import i18n from './i18n';

import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/theme.css';
window.$ = window.jQuery = require('jquery');

Vue.use(i18n);
Vue.config.productionTip = false;

new Vue({
  provide() {
    return { service };
  },
  router,
  render: h => h(App)
}).$mount('#app')
