<template>
  <div>
    <div class="header_fix">
      <xy28-header></xy28-header>
      <xy28-nav></xy28-nav>
    </div>
    <router-view></router-view>
    <xy28-footer></xy28-footer>
  </div>
</template>

<script>
import headerComponent from './components/Header';
import navComponent from './components/Nav';
import footerComponent from './components/Footer';

export default {
  components: {
    xy28Header: headerComponent,
    xy28Nav: navComponent,
    xy28Footer: footerComponent
  }
}
</script>