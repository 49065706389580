<template>
  <div>
    <div class="banner banner_subpage banner_games_lucky20ball">
      <div class="container">
        <div class="content">
          <div class="breadcrumbs">
            <ul>
              <li>
                <router-link to="/">STARTSEITE</router-link><span>/</span>
              </li>
              <li>
                <router-link :to="{ name: 'Games' }">Spiel - Glück 20 BALL</router-link>
              </li>
            </ul>
          </div>
          <h1 text="Spiel">Spiel</h1>
          <p text="Glück 20 BALL">Glück 20 BALL</p>
        </div>
      </div>
    </div>
    <div class="main subpage">
      <div class="container">
        <div class="subpage_content">
          <div class="left_content">
            <div class="content">
              <div class="line_top_a"></div>
              <div class="scroll_nav" :style="isStickNav? 'position:fixed;top:115px;' : ''">
                <ul>
                  <li :class="getNavLinkClass(0)">
                    <a href="#title_1">Glück 20 Ball</a>
                  </li>
                  <li :class="getNavLinkClass(1)">
                    <a href="#title_2">Wie zu spielen</a>
                  </li>
                  <li :class="getNavLinkClass(2)">
                    <a href="#title_3">Spielen mit QuickPicks</a>
                  </li>
                  <li :class="getNavLinkClass(3)">
                    <a href="#title_4">Wie man gewinnt</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right_content">
            <div class="content">
              <div class="line_top_b"></div>
              <div class="article_content">
                <div id="title_1" style="display:block;position:relative;top:-115px;visibility:hidden;"></div>
                <div class="article">
                  <div class="pattern">
                    <img src="/images/games_lucky20ball_pattern_1.png" alt="">
                  </div>
                  <div class="paragraph">
                    <div class="heading large">Glück 20 Ball-Hochfrequenz-Lotteriespiele</div>
                    <p>Glück 20 Ball Ist ein beliebtes europäisches und amerikanisches Lotteriespiel, Ziehunggeschwindigkeit mit hoher Frequenz und große Akkumulation von Jackpot, fast in jedem Jahr werden Hunderte von Menschen durch Glück 20 Bal, um ein Millionär zu werden, können Sie auch zusammenkommen, um diesen Jackpot zu gewinnen!</p>
                  </div>
                </div>
                <div id="title_2" style="display:block;position:relative;top:-105px;visibility:hidden;"></div>
                <div class="article">
                  <div class="pattern">
                    <img src="/images/games_lucky20ball_pattern_2.png" alt="">
                  </div>
                  <div class="paragraph">
                    <div class="heading large">Wie zu spielen</div>
                    <p>Glück 20 Ball ist sehr einfach zu spielen, mit 20 Kugeln, die alle fünf Minuten zufällig von Ball 1 bis 80 ezogen werden, als Ziehungsnummer für diesen Zeitraum.
                      Sie können 1 bis 20 Zahlen pro Ausgabe von Bälle 1 bis 80 setzen.
                      Um Jackpot zu gewinnen, müssen Sie 20 Zahlen entsprechen.</p>
                  </div>
                </div>
                <div id="title_3" style="display:block;position:relative;top:-95px;visibility:hidden;"></div>
                <div class="article">
                  <div class="pattern">
                    <img src="/images/games_lucky20ball_pattern_3.png" alt="">
                  </div>
                  <div class="paragraph">
                    <div class="heading large">Spielen mit QuickPicks</div>
                    <p>QuickPicks-Wettoptionen sind Ihre beste Wette, wenn Sie zum ersten Mal spielen oder eine schnelle Wette platzieren möchten, da diese Zahlen zufällig generiert werden und je höher die Anzahl der Wettperioden das Schema bedeutet, dass es einfacher ist, Gewinn zu bekommen.</p>
                    <table>
                      <tbody>
                        <tr>
                          <td>Maxi QuickPick</td>
                          <td>50 games</td>
                        </tr>
                        <tr>
                          <td>Jumbo QuickPick</td>
                          <td>36 games</td>
                        </tr>
                        <tr>
                          <td>Mega QuickPick</td>
                          <td>25 games</td>
                        </tr>
                        <tr>
                          <td>Super QuickPick</td>
                          <td>18 games</td>
                        </tr>
                        <tr>
                          <td>Regular QuickPick</td>
                          <td>14 games</td>
                        </tr>
                        <tr>
                          <td>12 Game QuickPick</td>
                          <td>12 games</td>
                        </tr>
                        <tr>
                          <td>6 Game QuickPick</td>
                          <td>6 games</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="title_4" style="display:block;position:relative;top:-85px;visibility:hidden;"></div>
                <div class="article">
                  <div class="pattern">
                    <img src="/images/games_lucky20ball_pattern_4.png" alt="">
                  </div>
                  <div class="paragraph">
                    <div class="heading large">Wie man Glück 20 Ball gewinnt</div>
                    <table class="line_chart">
                      <tbody>
                        <tr>
                          <th>Matching<br>Numbers</th>
                          <th>Prize</th>
                        </tr>
                        <tr>
                          <td>20</td>
                          <td>
                            <div class="bar jackpot">Jackpot</div>
                          </td>
                        </tr>
                        <tr>
                          <td>19</td>
                          <td>
                            <div class="bar">100.000,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>18</td>
                          <td>
                            <div class="bar">50.000,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>17</td>
                          <td>
                            <div class="bar">10.000,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>16</td>
                          <td>
                            <div class="bar">5.000,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>15</td>
                          <td>
                            <div class="bar">1.000,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>
                            <div class="bar">500,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>
                            <div class="bar">300,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>
                            <div class="bar">100,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>
                            <div class="bar">50,00 €</div>
                          </td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>
                            <div class="bar">10,00 €</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isStickNav: false,
      navActiveIndex: 0
    };
  },
  methods: {
    detectStickNav() {
      var scrollTop = window.scrollY;

      this.isStickNav = scrollTop > 320;
    },
    detectNavActive() {
      var scrollTop = window.scrollY;
      var firstBoundary = 0;
      var secondBoundary = 530;
      var thirdBoundary = 740;
      var fourthBoundary = 1245;

      if (scrollTop >= firstBoundary && scrollTop < secondBoundary)
        this.changeNavActiveIndex(0);
      else if (scrollTop >= secondBoundary && scrollTop < thirdBoundary)
        this.changeNavActiveIndex(1);
      else if (scrollTop >= thirdBoundary && scrollTop < fourthBoundary)
        this.changeNavActiveIndex(2);
      else
        this.changeNavActiveIndex(3);
    },
    changeNavActiveIndex(index) {
      this.navActiveIndex = index;
    },
    getNavLinkClass(index) {
      return this.navActiveIndex === index ? 'active' : '';
    }
  },
  mounted() {
    window.addEventListener('scroll', this.detectStickNav);
    window.addEventListener('scroll', this.detectNavActive);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.detectStickNav);
    window.removeEventListener('scroll', this.detectNavActive);
  }
}
</script>