import request from './request';

const lotteryId = 63;

const getJackPotAsync = () => request.get('jackpot');

const getHistoryAsync = (begin, end, limit, skip) => request.get('lotto/GetDataByRange', { lotteryId, begin, end, limit, skip });

const getCurrentIssueAsync = () => request.get('lotto/GetCurrentIssue', { lotteryId });

export default {
    getJackPotAsync,
    getHistoryAsync,
    getCurrentIssueAsync
};