<template>
  <nav>
    <div class="container">
      <div class="menu">
        <div class="dropdown_box" @mouseover="showSpiel" @mouseout="hideSpiel" @click="toggleSpielVisible">
          <div class="btn">
            <a href="javascript:void(0)">Spiel</a>
          </div>
          <div class="item" :style="spielVisible ? 'display:block':''">
            <ul>
              <li>
                <router-link :to="{ name: 'Games' }">
                  <img src="/images/dropdown_game_logo.png" alt="">
                  <div>Spiel</div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="dropdown_box" @mouseover="showErgebnisse" @mouseout="hideErgebnisse" @click="toggleErgebnisseVisible">
          <div class="btn">
            <a href="javascript:void(0)">Ergebnisse</a>
          </div>
          <div class="item" :style="ergebnisseVisible ? 'display:block':''">
            <ul>
              <li>
                <router-link :to="{ name: 'Results' }">
                  <img src="/images/dropdown_game_logo.png" alt="">
                  <div>Ergebnisse</div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="btn">
          <router-link :to="{ name: 'About' }">
            Über
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      spielVisible: false,
      ergebnisseVisible: false
    };
  },
  methods: {
    showSpiel() {
      this.hideErgebnisse();
      this.spielVisible = true;
    },
    hideSpiel() {
      this.spielVisible = false;
    },
    toggleSpielVisible() {
      if (this.spielVisible)
        this.hideSpiel();
      else
        this.showSpiel();
    },
    showErgebnisse() {
      this.hideSpiel();
      this.ergebnisseVisible = true;
    },
    hideErgebnisse() {
      this.ergebnisseVisible = false;
    },
    toggleErgebnisseVisible() {
      if (this.ergebnisseVisible)
        this.hideErgebnisse();
      else
        this.showErgebnisse();
    },
  }
}
</script>