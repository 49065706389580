<template>
  <header>
    <div class="container">
      <div class="flex_content">
        <div class="header_logo">
          <router-link to="/">
            <img src="/images/header_logo.png" alt="">
          </router-link>
          <div class="text">
            Nordrhein<br>
            Westfalen<br>
            Deutschland
          </div>
        </div>
        <div class="header_pattern">
          <div class="text">
            Service plattform EU Lotto Ltd
          </div>
          <img src="/images/header_eu_lotto.png" alt="">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {

}
</script>