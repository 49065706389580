<template>
  <footer>
    <div class="line">
      <div class="container">
        <div class="flex_content">
          <div class="sit_map">
            <li>
              <router-link :to="{ name: 'Games' }">Spiel</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Results' }">Ergebnisse</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'About' }">Über</router-link>
            </li>
          </div>
          <div class="copyright">© Glücks lotto</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="flex_content gap">
        <div class="logo">
          <router-link to="/">
            <img src="/images/header_logo.png" alt="">
          </router-link>
        </div>
        <div class="text">
          Die Website wird durch die Deutsche Lotto- und Sportwetten Limited (DLSL) betrieben und ermöglicht es Spielern u.a., Wetten auf die Ergebnisse staatlicher und internationaler Lotterien abzugeben. DLSL leitet die Tipps eines Mitspielers an die European Lotto and Betting Limited (ELBL), die als Buchmacherin handelt, weiter. Ein Spielvertrag mit staatlichen Lotteriegesellschaften kommt hierbei nicht zustande. Bei der Abgabe von Tipps handelt DLSL namens, im Auftrag sowie auf Anweisung der Mitspieler. Für das Angebot von DLSL besteht kein Widerrufsrecht seitens des Spielers. Die ELBL ist in Malta unter der Lizenznummer MGA/B2C/609/2018, ausgestellt am 25.02.2019, lizenziert und durch die Malta Gaming Authority reguliert.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>