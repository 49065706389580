<template>
  <div class="calendar">
    <div ref="placeholder"></div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      innerVal: ''
    };
  },
  watch: {
    value(val, oldVal) {
      this.innerVal = val;

      var placeholder = this.$refs.placeholder;

      if (!placeholder)
        return;

      $(placeholder).datepicker('setDate', new Date(val));
    }
  },
  methods: {
    onSelect(date) {
      this.innerVal = date;

      this.$emit('input', date);
      this.$emit('change', date);
    }
  },
  mounted() {
    var placeholder = this.$refs.placeholder;

    var dayNamesMin = this.$moment.weekdaysMin();
    var monthNames = this.$moment.months();
    var monthNamesShort = this.$moment.monthsShort();
    var dayNames = this.$moment.weekdays();
    var dayNamesShort = dayNamesMin;
    var defaultTime = this.$momentTz.format('YYYY-MM-DD');

    $(placeholder).datepicker({
      defaultDate: defaultTime,
      dateFormat: 'yy-mm-dd',
      dayNamesMin, monthNames, monthNamesShort, dayNames, dayNamesShort,
      onSelect: this.onSelect
    });
  },
  beforeDestroy() {
    $(this.$refs.placeholder).datepicker('hide').datepicker('destroy');
  }
}
</script>